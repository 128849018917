// extracted by mini-css-extract-plugin
export var conatiner = "PreviousExperience-module--conatiner--406b1";
export var container = "PreviousExperience-module--container--e7718";
export var description = "PreviousExperience-module--description--65310";
export var duration = "PreviousExperience-module--duration--f3a8c";
export var durationEnd = "PreviousExperience-module--durationEnd--5f04f";
export var durationStart = "PreviousExperience-module--durationStart--43f9a";
export var h1 = "PreviousExperience-module--h1--2a551";
export var highlights = "PreviousExperience-module--highlights--599aa";
export var highlightsTitle = "PreviousExperience-module--highlightsTitle--34d11";
export var item = "PreviousExperience-module--item--66adb";
export var title = "PreviousExperience-module--title--bc97e";